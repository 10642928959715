import {
	Component,
	Inject,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	SecurityContext,
	TransferState,
	afterRender,
	makeStateKey,
} from '@angular/core';
import { Globals } from './shared/globals';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TokenExpirationDialogComponent } from './shared/components/dialogs/token-expiration-dialog/token-expiration-dialog.component';
import { LocalStorageEnum } from './shared/localStorage.enum';
import { TownhallService } from './shared/services/townhall.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Townhall, TypeOfCity } from './shared/models/townhall.model';
import { AuthHelper } from './shared/helpers/auth.helper';
import { ImageHelper } from './shared/helpers/image.helper';
import { Subscription } from 'rxjs';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { UserInfo } from 'angular-oauth2-oidc';
import { AddSignatureDialogComponent } from './shared/components/dialogs/add-signature-dialog/add-signature-dialog.component';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CommonModule, isPlatformBrowser, isPlatformServer } from '@angular/common';

const TOWNHALL_DETAILS_KEY = makeStateKey<any>('townhall-details');
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [CommonModule, RouterOutlet],
})
export class AppComponent implements OnDestroy, OnInit {
	tokenExpirationDialog: MatDialogRef<TokenExpirationDialogComponent>;
	addSignatureDialog: MatDialogRef<AddSignatureDialogComponent>;
	favIcon: HTMLLinkElement;
	loaded = false;
	userInfo: UserInfo;
	signatureReminder: string;
	private signatureReminderSubscription: Subscription;
	private sub: Subscription;

	constructor(
		private dialog: MatDialog,
		private townhallService: TownhallService,
		private _sanitizer: DomSanitizer,
		private title: Title,
		private authHelper: AuthHelper,
		private imageHelper: ImageHelper,
		private route: ActivatedRoute,
		private router: Router,
		private transferState: TransferState,
		@Inject(PLATFORM_ID) private platformId
	) {
		if (isPlatformServer(this.platformId)) {
			this.townhallService.getTownhallDetails().subscribe((response) => {
				this.transferState.set(TOWNHALL_DETAILS_KEY, response.body);
				this.updateAndLoad(response.body);
			});
		} else if (isPlatformBrowser(this.platformId)) {
			const townhallDetails = this.transferState.get(TOWNHALL_DETAILS_KEY, null);
			if (townhallDetails) {
				this.updateAndLoad(townhallDetails);
			} else {
				this.townhallService.getTownhallDetails().subscribe((response) => {
					this.updateAndLoad(response.body);
				});
			}
			this.favIcon = document.querySelector('#favIcon');
		}
		this.authHelper.scheduleSsoTokenRefresh();
		this.initOauth();
		this.checkToken();
		this.handleOauthQueryParams();
	}
	ngOnInit(): void {
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			if (event.url === '/candidate/servicii') {
				this.handleSignatureReminder();
			}
		});
	}

	private handleOauthQueryParams() {
		this.sub = this.route.queryParams.subscribe((params) => {
			const code = params['code'];
			if (code) {
				this.authHelper.getUserInfoFromCode(code);
			}
		});
	}

	private updateAndLoad(details: any) {
		Globals.TOWNHALL_DETAILS = details;
		this.updatePageDetails(details);
		this.loaded = true;
	}

	private getTownhallDetails() {
		this.townhallService.getTownhallDetails().subscribe((response) => {
			if (isPlatformBrowser(this.platformId)) {
				Globals.TOWNHALL_DETAILS = response.body;
				this.updatePageDetails(Globals.TOWNHALL_DETAILS);
			}
			this.loaded = true;
		});
	}

	private initOauth() {
		this.authHelper.initializeOauthConfiguration();
	}

	private checkToken() {
		if (isPlatformBrowser(this.platformId)) {
			if (localStorage.getItem(LocalStorageEnum.AUTH_TOKEN)) {
				this.authHelper.checkToken(
					() => {},
					() => this.displayTokenError()
				);
			}
		}
	}

	private handleSignatureReminder() {
		this.signatureReminderSubscription = this.authHelper
			.getSignatureReminderSubject()
			.subscribe((signatureReminder: Boolean) => {
				this.signatureReminder = localStorage.getItem('signature_reminder') ?? signatureReminder.toString();
				if (this.signatureReminder == 'true') {
					this.displaySignatureReminder();
				}
			});
	}

	private displayTokenError(): void {
		this.tokenExpirationDialog = this.dialog.open(TokenExpirationDialogComponent, {
			data: { message: 'Sesiunea ta a expirat!' },
		});
		this.tokenExpirationDialog.afterClosed().subscribe((agreed) => {
			if (agreed) {
				this.authHelper.logout();
			}
		});
	}

	private displaySignatureReminder(): void {
		this.addSignatureDialog = this.dialog.open(AddSignatureDialogComponent);
		this.addSignatureDialog.afterClosed().subscribe(() => {
			localStorage.setItem('signature_reminder', 'false');
		});
	}

	private updatePageDetails(townhall: Townhall) {
		if (townhall) {
			if (TypeOfCity[townhall.typeOfCity]) {
				this.title.setTitle(`${TypeOfCity[townhall.typeOfCity]} ${townhall.city}`);
			} else {
				this.title.setTitle(townhall.city);
			}

			const logo = this.imageHelper.getImage(townhall.logo);
			if (isPlatformBrowser(this.platformId) && this.favIcon?.href) {
				this.favIcon.href = this._sanitizer.sanitize(SecurityContext.RESOURCE_URL, logo);
			}
		}
	}

	ngOnDestroy(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.sub.unsubscribe();
			this.signatureReminderSubscription.unsubscribe();
		}
	}
}
