import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
	},
	{
		path: 'superagent',
		loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
	},
	{
		path: 'candidate',
		loadChildren: () => import('./modules/candidate/candidate.module').then((m) => m.CandidateModule),
	},
	{
		path: 'functionar',
		loadChildren: () => import('./modules/clerk/clerk.module').then((m) => m.ClerkModule),
	},
	{ path: '**', redirectTo: '' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
export { routes };
