import { ApplicationConfig, LOCALE_ID, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { AppRoutingModule, routes } from './app-routing.module';
import { BrowserModule, provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule, provideOAuthClient } from 'angular-oauth2-oidc';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from './shared/services/alert.service';
import { TokenService } from './shared/services/token.service';
import { AuthHelper } from './shared/helpers/auth.helper';
import { AuthService } from './shared/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ScriptLoaderService } from './shared/services/script-loader-service.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from './utils/CustomPaginatorConfiguration';
import { registerLocaleData } from '@angular/common';
import localeRO from '@angular/common/locales/ro';
import { NgForm } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { RMP_DATE_FORMATS, RmpDateformat } from './utils/rmp-dateformat';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { ImageHelper } from './shared/helpers/image.helper';
import { ContactService } from './shared/services/contact-form.service';
import { PermissionsStorage } from './utils/permissionsStorage';
import { DashboardService } from './shared/services/dashboard.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TaskService } from './shared/services/task.service';
import { ReferralService } from './shared/services/referral.service';
import { DataStorage } from './utils/dataStorage';
import { MassFilterUsersService } from './shared/services/massFilterUsers.service';
import { MassCommunicationService } from './shared/services/mass-communication.service';
import { FaqAdminComponent } from './modules/admin/components/admin/faq-admin/faq-admin.component';
import { FaqService } from './shared/services/faq.service';
import { SubjectAreaService } from './shared/services/subject-area.service';
import { FileUploadService } from './shared/services/file-upload.service';
import { NotificationsService } from './shared/services/notifications.service';
import { ApplicationMessagesService } from './shared/services/application-messages.service';
import { ApplicationService } from './shared/services/application.service';
import { AdminService } from './modules/admin/services/admin.service';
import { UserService } from './shared/services/user.service';
import { CourseService } from './shared/services/course.service';
import { provideHttpClient, withFetch, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';

registerLocaleData(localeRO);
export const appConfig: ApplicationConfig = {
	providers: [
		provideZoneChangeDetection({ eventCoalescing: true , runCoalescing: true}),
		provideRouter(routes),
		provideClientHydration(),
		provideHttpClient(withInterceptorsFromDi(), withJsonpSupport(), withFetch()),
		provideClientHydration(),
		provideOAuthClient({ resourceServer: { allowedUrls: ['http://localhost:8081'], sendAccessToken: false } }),
		importProvidersFrom(BrowserAnimationsModule, NgbModule),
		CourseService,
		UserService,
		AdminService,
		AppRoutingModule,
		ApplicationService,
		ApplicationMessagesService,
		NotificationsService,
		FileUploadService,
		SubjectAreaService,
		FaqService,
		FaqAdminComponent,
		MassCommunicationService,
		MassFilterUsersService,
		DataStorage,
		ReferralService,
		TaskService,
		DragDropModule,
		DashboardService,
		PermissionsStorage,
		ContactService,
		AuthService,
		ImageHelper,
		{ provide: DateAdapter, useClass: RmpDateformat },
		{ provide: MAT_DATE_FORMATS, useValue: RMP_DATE_FORMATS },
		{
			provide: MAT_RADIO_DEFAULT_OPTIONS,
			useValue: { color: 'primary' },
		},
		AlertService,
		TokenService,
		AuthHelper,
		CookieService,
		ScriptLoaderService,
		NgForm,
		{ provide: LOCALE_ID, useValue: 'ro' },
		{ provide: MatPaginatorIntl, useValue: CustomPaginator() },
	],
};
